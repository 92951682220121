.contact-page {
  width: 60%;
  margin: auto;
  h1 {
    padding-bottom: 0.2em;
    border-bottom-style: solid;
    border-bottom-width: 3.1px;
    width: fit-content;
  }
  p {
    margin: 5% 0% 5% 0%;
    font-size: 1.5rem;
  }
  .contact-form {
    max-width: 100%;

    ul {
      padding: 0;
      margin: 0;

      li {
        list-style: none;
        margin-bottom: 1.5%;
        opacity: 0;
        overflow: hidden;
        display: block;
        clear: both;
        position: relative;
        animation: fadeInUp 2s 2s;
        animation-fill-mode: forwards;
      }

      li.half {
        width: 49%;
        margin-left: 2%;
        float: left;
        clear: none;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    input[type='text'],
    input[type='email'] {
      min-width: 100%;
      max-width: 100%;
      border: 0;
      background: #2b2b2b;
      min-height: 5vh;
      font-size: 1.5rem;
      color: #fff;
      padding: 0 20px;
      box-sizing: border-box;
    }

    textarea {
      width: 100%;
      float: right;
      border: 0;
      background: #2b2b2b;
      height: 15vh;
      font-size: 1/3rem;
      color: #fff;
      padding: 20px;
      box-sizing: border-box;
      min-height: 150px;
      resize: none;
    }

    .flat-button {
      margin-top: 3%;
      // min-width: 30%;
      background-color: inherit;
    }
  }

  .map-wrap {
    background: rgba(8, 253, 216, 0.1);
    float: right;
    width: 53%;
    height: 115%;
    margin-top: -3%;
  }

  .leaflet-container {
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: backInRight 1s 1.2s;
    animation-fill-mode: forwards;
  }

  @media screen and (max-width: 640px) {

      width: 100%;

    
  }
}
