

.popup{
    z-index: 20;
    position: fixed !important;
    top:0;
    left:-3em;
    width: 100vw;
    height: 100vh;
    // background-color: rgb(0,0,0,0.75);

    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-image{
    position: relative;
    width: 50vw;
    height: 70vh;
    border: 1px solid rgb(90, 94, 94);
}

.close-icon{
    color:white;
    width: 2.5vw;
    height: 2.5vh;
    // border: 1px solid red;
}

.popup-inner{
    position: relative;
    // animation: fadeIn 0.2s 0.5s backwards;
        -webkit-animation: popup-inner 0.4s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
                animation: popup-inner 0.4s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;

}

.close-btn{
    z-index: 1;
    background-color: inherit;
    position: absolute;
    border: 0;
    right: 0;

}

.btn{
    position: absolute;
    background-color: rgb(72, 30, 12);
    bottom:0;
    width: 7%;
    height: 3%;
    color: white;
    border-radius: 7px;
    border:1px solid black;
}

.next{
    left: 8%;
}

img{
    width: 100%;
    height: 100%;
    // object-fit: cover;
}

 @-webkit-keyframes popup-inner {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes popup-inner {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @media (max-width: 450px) {
    .popup{
        left:-1.4em;
    }
    
    .popup-image{
        width: 80vw;
    }
    .close-icon{
        color:white;
        width: 8vw;
        height: 4vh;
        // border: 1px solid red;
    }
  }