.terminal {
  position: fixed;
  bottom: 0;
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  display: grid;
  place-items: center;
  background-image: url('../../assets/images/background-terminal.png');
  background-size: cover;
  background-position: center;
  // border: 1px solid red;
  .terminal-container {
    position: relative;
    margin-top: 20%;
    min-width: 80vw;
    max-width: 80vw;
    min-height: 40vh;
    max-height: 50vh;
    border-radius: 7px;
    box-shadow: rgba(155, 93, 131, 0.56) 0px 10px 40px 5px;
    -webkit-animation: slide-top 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: slide-top 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }

  .terminal-top-element {
    position: relative;
    background-color: rgb(27, 28, 27);
    opacity: 0.85;
    min-height: 3vh;
    border-radius: 7px 7px 0 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1em 0 1em;
  }

  .terminal-bottom-element {
    position: absolute;
    background-color: rgb(37, 37, 38);
    -webkit-backdrop-filter: blur(8px); /* Safari 9+ */
    backdrop-filter: blur(8px); /* Chrome and Opera */
    opacity: 0.85;
    min-width: 100%;
    max-width: 100%;
    min-height: 92%;
    max-height: 92%;
    padding: 1.5vw;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    border-radius: 0 0 7px 7px;
    font-size: 1.32rem;
    //   font-size: calc(0.75em + 1vmin);
  }

  .terminal-bottom-element::-webkit-scrollbar {
    display: none;
  }


  input,
  input:focus {
    border: 0;
    outline: 0;
    outline: none !important;
    background-color: inherit;
    caret-color: rgb(214, 203, 203);
    min-height: 50%;
    min-width: 60%;
    resize: none;
    color: white;
    line-height: 130%;
    letter-spacing: 2px;
    margin-left: 0.5em;
  }
  
  .command-area {
    display: flex;
    padding-right: 1em;
    margin-top: 1%;
    // border: 1px solid red;
  }
  
  .command-area > p {
    // border: 1px solid red;
    
    // border: 1px solid red;
  }
  @media (max-width: 450px) {
    .command-area >P{
      width: 10%;
      margin-left: 0em;
      margin-right: 0em;
    }
    input,input:focus{
      // border: 1px solid red;
      margin-left: 0em;
      }
  }

  .text-history {
    position: relative;
    margin-left: 1.5%;
    color: white;
    // overflow: hidden;
  }

  @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100px);
      transform: translateY(-100px);
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100px);
      transform: translateY(-100px);
    }
  }
}
