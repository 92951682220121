.logo-terminal {
  background-image: url('../../assets/projects-images/logo1.png');
}
.logo-typing {
  background-image: url('../../assets/projects-images/logo-typing.jpg');
}
.logo-cafe {
  background-image: url('../../assets/projects-images/logo-twitch.jpg');
}
.logo-pantry {
  background-image: url('../../assets/projects-images/logo3.png');
}
.logo-discord {
  background-image: url('../../assets/projects-images/logo5.png');
}
.logo-mysite {
  background-image: url('../../assets/projects-images/logo4.png');
}

.projects-component {
  font-size: 1.31em;
  font-weight: 400;
  color: var(--paragraph-color);
  h1 {
    padding-bottom: 0.3em;
    border-bottom-style: solid;
    border-bottom-width: 3.1px;
    width: fit-content;
  }

  .project-des {
    max-width: 65%;
    // margin-left: 1.5em;
    margin-bottom: 2em;
    line-height: 1.8rem;
    font-family: 'American Typerwriter';
  }

  .project-table {
    width: min(100%);
    // border: 1px solid red;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // gap: 1rem;
    box-shadow: 0 2px 15px 0 rgb(158, 153, 153);
    .single-project {
      margin: auto;
      width: 100%;
      min-height: 15vh;
      background-size: cover;
      background-position: center;
      background-color: rgb(153, 151, 151);
      background-blend-mode: multiply;
      transition: all 1s;
      button {
        border: none;
        width: 100%;
        height: 15vh;
        background-color: transparent;
      }
      .project-hover-div {
        display: grid;
        place-items: center;
        margin: auto;
        width: 30%;
        height: 8vh;
        border-radius: 50%;
        font-size: 1.1em;
        font-family: 'American Typerwriter';
        font-weight: 300;
        letter-spacing: 0.2rem;
        background-color: var(--main-header-color);
        box-shadow: 0 0px 0px 0 var(--main-header-color);
        -webkit-animation: scale-up-center 0.1s linear both;
        animation: scale-up-center 0.1s linear both;
      }

      :hover {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        box-shadow: 0 5px 20px 0 var(--main-header-color);
      }
    }
  }

  .GuestInfo{
    width: 20%;
    height: 3vh;
    margin-bottom: 10%;
    font-size: 20px;
    padding: 1%;
  }

  @media (max-width: 640px) {
    .project-des {
      max-width: 100%;
    }
    .project-table {
      grid-template-columns: repeat(2, 1fr);
      gap: 0.4rem;
    }
    .single-project {
      // border: 1px solid rgb(153, 151, 151);
      box-shadow: 0 1px 3px 0 rgb(158, 153, 153);
      height: 20vh;
      :hover {
        -webkit-transform: scale(0);
        transform: scale(1, 1);
        box-shadow: 0 0px 0px 0 var(--main-header-color);
      }
    }
    button {
      height: 20vh;
    }
  }
  @media (max-height: 810px){
    .project-table {
      grid-template-columns: repeat(2, 1fr);
      gap: 0.4rem;
    }
  }

  @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}
